import { createSelector, } from 'reselect'
import { createSelector as createSelectorOrm, } from 'redux-orm'
import orm from '../models'
import { DNR_GROUP_SIDS, PERSON_TYPE_GOV_CUST, PERSON_TYPE_PRIVATE, PERSON_TYPE_AGENT, } from './env'


export const checkedLookupDataSelector = state => state.api.lookupDataChecked
export const propsSelector = (state, props) => {
  return props
}
export const filterSelector = (state, props, filterObj) => filterObj

export const idSelector = (state, props) => {
  let id = props
  // If it's already an integer, pass it through
  if (Number.isInteger(id)) {
    return id
  }
  // Parse the value to an int
  id = parseInt(id)
  // If it's an integer now,  pass it through
  if (Number.isInteger(id)) {
    return id
  }
  // Otherwise return null
  return null
}

/**
 * Returns a redux-orm based selector enriched with any additional selectors provided
 */
export const ormSelector = function () {
  // `arguments` is a built-in object for functions that capture that arguments in an array-like object
  // In this implementation, `arguments` contains the selectors and callback functions passed to this function
  // Since the number of selectors can vary, we use this function to abstract away the base `orm` requirement
  const args = Array.prototype.slice.call(arguments)
  return createSelectorOrm(orm, ...args)
}

/**
 * Returns a redux-orm based selector enriched with a `idSelector` and any additional selectors provided
 */
export const ormByIdSelector = function () {
  const args = Array.prototype.slice.call(arguments)
  return createSelectorOrm(orm, idSelector, ...args)
}

/**
 * Returns a redux-orm based selector enriched with a `propsSelector` and any additional selectors provided
 */
export const ormWithPropSelector = function () {
  const args = Array.prototype.slice.call(arguments)
  return createSelectorOrm(orm, propsSelector, ...args)
}

export const getValueOrDefault = function (value, def) {
  if (value === undefined || value === null) {
    return def
  }
  return value
}

export const addressMapper = (val) => {
  return {
    AddressId      : val.AddressId,
    StreetLine1    : val.StreetLine1,
    StreetLine2    : getValueOrDefault(val.StreetLine2, ''),
    StreetLine3    : getValueOrDefault(val.StreetLine3, ''),
    AddressCity    : val.AddressCity,
    AddressState   : val.AddressState,
    AddressZipCode : val.AddressZipCode,
    AddressCountry : val.AddressCountry,
    AddressTypeId  : val.AddressTypeId,
    CountyId       : val.CountyId,
    Active         : val.Active,
    CreateBy       : val.CreateBy,
    CreateDate     : val.CreateDate,
    UpdateBy       : val.UpdateBy,
    UpdateDate     : val.UpdateDate,
    IsLocal        : getValueOrDefault(val.IsLocal, false),
  }
}
export const phoneMapper = (val) => {
  return {
    PhoneId              : val.PhoneId,
    PhoneNumber          : val.PhoneNumber,
    PhoneExtension       : getValueOrDefault(val.PhoneExtension, ''),
    PhoneNumberConfirmed : val.PhoneNumberConfirmed,
    PhoneTypeId          : val.PhoneTypeId,
    ReceivesAlerts       : val.ReceivesAlerts || false,
    Active               : val.Active,
    CreateBy             : val.CreateBy,
    CreateDate           : val.CreateDate,
    UpdateBy             : val.UpdateBy,
    UpdateDate           : val.UpdateDate,
    IsLocal              : val.IsLocal || false,
  }
}

export const emailMapper = email => {
  if (!email) {
    return email
  }
  return {
    EmailId                : email.EmailId,
    EmailAddress           : getValueOrDefault(email.EmailAddress, ''),
    NormalizedEmailAddress : getValueOrDefault(email.NormalizedEmailAddress, ''),
    EmailConfirmed         : getValueOrDefault(email.EmailConfirmed, false),
    CreateDate             : email.CreateDate,
    CreateBy               : email.CreateBy,
    UpdateDate             : email.UpdateDate,
    UpdateBy               : email.UpdateBy,
  }
}

export const networkStateSelector = state => state.offline

export const showLoadingSelector = state => state.app.showLoading

export const appIsOnlineSelector = createSelector(
  networkStateSelector,
  ({ online, }) => online
)

// <BurnPermit>

export const permitByBurnPermitNumberSelector = ormWithPropSelector(
  ({ BurnPermit, }, BurnPermitNumber) => {
    return BurnPermit.get({ BurnPermitNumber: BurnPermitNumber.toUpperCase(), })
  }
)

export const permitByIdSelector = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return null
    }
    return BurnPermit.withId(BurnPermitId)
  }
)

export const permitIdAndSectionIdsByPermitIdSelector = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return null
    }

    let permit = BurnPermit.filter({ BurnPermitId, }).toRefArray()
    if (permit) {
      permit = permit.map(p => {
        return {
          BurnPermitId         : p.BurnPermitId,
          BurnPermitAreaId     : p.BurnPermitAreaId,
          BurnPermitSiteId     : p.BurnPermitSiteId,
          BurnPermitLocationId : p.BurnPermitLocationId,
          BurnPermitFuelId     : p.BurnPermitFuelId,
          BurnPermitNumber     : p.BurnPermitNumber || '',
        }
      })[0]
    }
    return permit
  }
)

export const permitStatus = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return null
    }

    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit) {
      return null
    }

    if (permit.BurnPermitStatusXrefs.exists()) {
      let BurnPermitStatusId = '', BurnPermitStatusName = '', BurnPermitStatusDescription = ''
      const statusXref = permit.BurnPermitStatusXrefs.orderBy('CreateDate', 'desc').first()

      if (statusXref.BurnPermitStatus.BurnPermitStatusName !== 'Issued') {
        BurnPermitStatusId = statusXref.BurnPermitStatus.BurnPermitStatusId
        BurnPermitStatusName = statusXref.BurnPermitStatus.BurnPermitStatusName
        BurnPermitStatusDescription = statusXref.BurnPermitStatus.BurnPermitStatusDescription
      }

      if ([ 'Issued', 'Revoked', ].includes(BurnPermitStatusName) === false) {
        const hasSignedPermit = permit.BurnPermitDocument
          .all()
          .toModelArray()
          .some(d =>
            d.BurnPermitDocumentType
            && d.BurnPermitDocumentType.BurnPermitDocumentTypeName === 'Signed Permit'
          )
        const signature = permit.BurnPermitSignature.first() || {}
        const isAutoApproved = signature.PermitEnvelopeId === '[AUTO-APPROVED]'
        if (hasSignedPermit === false && isAutoApproved !== true) {
          return null
        }
      }
      // If we're not dealing with an Issued Permit that hasn't been signed or a revoked
      // one, set the vars for their appropriate info
      if (!BurnPermitStatusName) {
        BurnPermitStatusId = statusXref.BurnPermitStatus.BurnPermitStatusId
        BurnPermitStatusName = statusXref.BurnPermitStatus.BurnPermitStatusName
        BurnPermitStatusDescription = statusXref.BurnPermitStatus.BurnPermitStatusDescription
      }

      return {
        BurnPermitStatusXrefId : statusXref.BurnPermitStatusXrefId,
        StatusDate             : statusXref.StatusDate,
        SiteInspected          : statusXref.SiteInspected,
        Comment                : statusXref.Comment,
        StatusDescription      : BurnPermitStatusDescription,
        Status                 : BurnPermitStatusName,
        BurnPermitStatusId     : BurnPermitStatusId,
        CreateBy               : statusXref.CreateBy,
        CreateDate             : statusXref.CreateDate,
        UpdateBy               : statusXref.UpdateBy,
        UpdateDate             : statusXref.UpdateDate,
      }
    }
    return null
  }
)

export const permitStatusHistory = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return null
    }
    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit) {
      return null
    }

    const statuses = []

    const permitStatuses = permit.BurnPermitStatusXrefs
    if (permitStatuses.exists()) {
      const statusXref = permit.BurnPermitStatusXrefs.orderBy('CreateDate', 'desc').first()
      const isRevoked = statusXref.BurnPermitStatus.BurnPermitStatusName === 'Revoked'
      const hasSignedPermit = permit.BurnPermitDocument
        .all()
        .toModelArray()
        .some(d =>
          d.BurnPermitDocumentType
          && d.BurnPermitDocumentType.BurnPermitDocumentTypeName === 'Signed Permit'
        )
      const signature = permit.BurnPermitSignature.first() || {}
      const isAutoApproved = signature.PermitEnvelopeId === '[AUTO-APPROVED]'
      if (hasSignedPermit || isAutoApproved || isRevoked) {
        permitStatuses
          .orderBy('StatusDate', 'desc')
          .toModelArray()
          .forEach((x) => {
            let statusName = ''
            if (x.BurnPermitStatus && !!x.BurnPermitStatus.BurnPermitStatusName) {
              statusName = x.BurnPermitStatus.BurnPermitStatusName
            }

            statuses.push({
              BurnPermitStatusXrefId : x.BurnPermitStatusXrefId,
              StatusDate             : x.StatusDate,
              Comment                : x.Comment,
              Status                 : statusName,
              CreateBy               : x.CreateBy,
              CreateDate             : x.CreateDate,
              UpdateBy               : x.UpdateBy,
              UpdateDate             : x.UpdateDate,
            })
          })
      }
      else {
        const issuedStatus = permitStatuses
          .toModelArray()
          .filter(s =>
            s.BurnPermitStatus
            && s.BurnPermitStatus.BurnPermitStatusName === 'Issued'
          )[0]
        if (issuedStatus) {
          const dnrHasSigned = !!signature.PermitApproverSignedBy
          if (dnrHasSigned !== true) {
            statuses.push({
              BurnPermitStatusXrefId : issuedStatus.BurnPermitStatusXrefId,
              StatusDate             : issuedStatus.StatusDate,
              Comment                : '[SYSTEM] Awaiting Approver Signature',
              Status                 : 'Issue Initiated',
              CreateBy               : issuedStatus.CreateBy,
              CreateDate             : issuedStatus.CreateDate,
              UpdateBy               : issuedStatus.UpdateBy,
              UpdateDate             : issuedStatus.UpdateDate,
            })
          }
          else {
            statuses.push({
              BurnPermitStatusXrefId : issuedStatus.BurnPermitStatusXrefId,
              StatusDate             : signature.PermitApproverSignedDate,
              Comment                : '[SYSTEM] Awaiting Burn Permit Applicant Signature',
              Status                 : 'Issue Initiated',
              CreateBy               : issuedStatus.CreateBy,
              CreateDate             : issuedStatus.CreateDate,
              UpdateBy               : issuedStatus.UpdateBy,
              UpdateDate             : issuedStatus.UpdateDate,
            })
          }
        }
      }
    }

    const newApplStatuses = permit.BurnPermitApplicationStatusXrefs
    if (newApplStatuses.exists()) {
      newApplStatuses
        .orderBy('StatusDate', 'desc')
        .toModelArray()
        .forEach((x) => {
          let statusName = ''
          if (x.BurnPermitApplicationStatus && !!x.BurnPermitApplicationStatus.BurnPermitApplicationStatusName) {
            statusName = x.BurnPermitApplicationStatus.BurnPermitApplicationStatusName
          }
          
          statuses.push({
            BurnPermitApplicationStatusXrefId : x.BurnPermitApplicationStatusXrefId,
            CreateBy                          : x.CreateBy,
            StatusDate                        : x.StatusDate,
            SiteInspected                     : x.SiteInspected,
            Comment                           : x.Comment,
            Status                            : statusName,
            BurnPermitApplicationStatusId     : x.BurnPermitApplicationStatusId,
            BurnPermitId                      : x.BurnPermitId,
            InternalOnly                      : x.InternalOnly,
            ApplicationSection                : x.ApplicationSection,
            AssignedTo                        : x.AssignedTo,
          })
        })
    }

    // Order by StatusDate desc just in case
    const sortedStatuses = statuses
      .sort((a, b) => {
        if (a.StatusDate < b.StatusDate) {
          return 1
        }
        if (a.StatusDate > b.StatusDate) {
          return -1
        }
        return 0
      })
    return sortedStatuses
  }
)

export const permitStatuses = ormSelector(
  ({ BurnPermitStatus, }) => {
    const statuses = BurnPermitStatus.all()
      .toModelArray()
      .map(s => {
        return {
          Value : s.BurnPermitStatusId,
          Text  : s.BurnPermitStatusName,
        }
      })
    return statuses
  }
)

export const permitApplicationStatuses = ormSelector(
  ({ BurnPermitApplicationStatus, }) => {
    const statuses = BurnPermitApplicationStatus.all()
      .toModelArray()
      .map(s => {
        return {
          Value : s.BurnPermitApplicationStatusId,
          Text  : s.BurnPermitApplicationStatusName,
        }
      })
    return statuses
  }
)

export const burnedAcresByPermitSelector = ormByIdSelector(
  ({ PostBurn, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return 0
    }
    const postBurns = PostBurn.filter({ BurnPermitId, }).toRefArray().map(p => {
      return {
        PostBurnId  : p.PostBurnId,
        BurnedAcres : p.BurnedBlackAcres || 0,
      }
    })
    const pbMap = new Map()
    for (let i = 0, len = postBurns.length; i < len; i++) {
      pbMap.set(postBurns[i].PostBurnId, postBurns[i])
    }
    const burnedAcres = [ ...pbMap.values(), ].reduce((a, b) => { return a + b.BurnedAcres }, 0)
    return burnedAcres
  }
)

export const burnedTonsByPermitSelector = ormByIdSelector(
  ({ PostBurn, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return 0
    }
    const postBurns = PostBurn.filter({ BurnPermitId, }).toRefArray()
      .map(p => {
        return {
          PostBurnId   : p.PostBurnId,
          ConsumedTons : p.ConsumedTons || 0,
        }
      })
    const pbMap = new Map()
    for (let i = 0, len = postBurns.length; i < len; i++) {
      pbMap.set(postBurns[i].PostBurnId, postBurns[i])
    }
    const burnedTons = [ ...pbMap.values(), ].reduce((a, b) => { return a + b.ConsumedTons }, 0)
    return burnedTons
  }
)

// </BurnPermit>
// <Direction>

export const directionsForSelectSelector = ormWithPropSelector(
  ({ Direction, }, filter) => {
    const dirs = Direction.all()
    if (dirs) {
      let models = dirs.toModelArray()
      if (typeof filter === 'function') {
        models = models.filter(filter)
      }
      return models.map((d) => {
        return {
          Value  : d.DirectionId,
          Text   : d.DirectionName,
          Abbrev : d.DirectionAbbreviation,
        }
      })
    }
    return []
  }
)

// </Direction>

export const countiesForSelectSelector = ormSelector(
  ({ County, }) => {
    const counties = County.all()
    if (counties) {
      return counties.toModelArray().map(r => {
        return {
          Value : r.CountyId,
          Text  : r.CountyName,
        }
      })
    }
    return []
  }
)

export const fireDistrictDepartmentsForSelectSelector = ormSelector(
  ({ FireDistrictDepartment, }) => {
    const fireDistrictDepartments = FireDistrictDepartment.all()
    if (fireDistrictDepartments) {
      return fireDistrictDepartments
        .orderBy('FireDistrictDepartmentDescription', 'asc')
        .toModelArray()
        .map(r => {
          return {
            Value : r.FireDistrictDepartmentId,
            Text  : r.FireDistrictDepartmentDescription,
          }
        })
    }
    return []
  }
)

// <LocationQuarter>

export const locationQuartersSelectSelector = ormSelector(
  ({ LocationQuarter, }) => {
    const locationQuarters = LocationQuarter.all()
    if (locationQuarters) {
      return locationQuarters.toModelArray().map((lq) => {
        return {
          Value : lq.LocationQuarterName,
          Text  : lq.LocationQuarterName,
        }
      })
    }
    return []
  }
)

// </LocationQuarter>

// <BurnPermitLocation>

export const permitLocationByIdSelector = ormByIdSelector(
  ({ BurnPermitLocation, }, BurnPermitLocationId) => {
    if (isNaN(BurnPermitLocationId)) {
      return null
    }
    const location = BurnPermitLocation.withId(BurnPermitLocationId)
    if (!location) {
      return null
    }
    return {
      BurnPermitLocationId,
      FireDistrictDepartmentId    : location.FireDistrictDepartmentId || '',
      FireDistrictDepartment      : location.FireDistrictDepartment,
      DrivingDirections           : location.DrivingDirections || '',
      SlopePercent                : location.SlopePercent || '',
      ElevationFeet               : location.ElevationFeet || '',
      RegionId                    : location.RegionId || '',
      Region                      : location.Region,
      CountyId                    : location.CountyId || '',
      County                      : location.County,
      AddressId                   : location.AddressId || '',
      Address                     : location.Address ? addressMapper(location.Address) : null,
      AddressLocation             : location.AddressLocation,
      Latitude                    : location.Latitude || '',
      Longitude                   : location.Longitude || '',
      LegalDescriptionSection     : location.LegalDescriptionSection || '',
      LegalDescriptionTownship    : location.LegalDescriptionTownship || '',
      LegalDescriptionRange       : location.LegalDescriptionRange || '',
      LegalDescriptionDirectionId : location.LegalDescriptionDirectionId || '',
      LegalDescriptionQuarter1    : location.LegalDescriptionQuarter1 || '',
      LegalDescriptionQuarter2    : location.LegalDescriptionQuarter2 || '',
      LegalDescriptionLocation    : location.LegalDescriptionLocation,
      IsUGA                       : location.IsUGA || false,
      CreateBy                    : location.CreateBy,
      CreateDate                  : location.CreateDate,
      UpdateBy                    : location.UpdateBy,
      UpdateDate                  : location.UpdateDate,
    }
  }
)

export const permitLocationLegalDesc = ormByIdSelector(
  ({ BurnPermitLocation, }, BurnPermitLocationId) => {
    if (isNaN(BurnPermitLocationId)) {
      return {}
    }
    const location = BurnPermitLocation.withId(BurnPermitLocationId)
    if (!location) {
      return {}
    }
    return {
      LegalDescriptionSection     : location.LegalDescriptionSection || '',
      LegalDescriptionTownship    : location.LegalDescriptionTownship || '',
      LegalDescriptionRange       : location.LegalDescriptionRange || '',
      LegalDescriptionDirectionId : location.LegalDescriptionDirectionId || '',
      LegalDescriptionQuarter1    : location.LegalDescriptionQuarter1 || '',
      LegalDescriptionQuarter2    : location.LegalDescriptionQuarter2 || '',
      LegalDescriptionLocation    : location.LegalDescriptionLocation,
      BurnPermitLocationId        : location.BurnPermitLocationId,
    }
  }
)

export const locationAddress = ormByIdSelector(
  permitLocationByIdSelector,
  ({ Address, }, locationId, location) => {
    let addr = null

    if (location) {
      if (location.Address) {
        // if location has properly related address
        addr = addressMapper(location.Address)
      }
      else if (location.AddressId) {
        // if location does not have properly related address
        const addrById = Address.withId(location.AddressId)
        if (addrById) {
          addr = addressMapper(addrById)
        }
      }
      else {
        addr = {
          AddressId      : '',
          StreetLine1    : '',
          StreetLine2    : '',
          StreetLine3    : '',
          AddressCity    : '',
          AddressState   : 'WA',
          AddressZipCode : '',
          AddressCountry : '',
          AddressTypeId  : '',
          CountyId       : '',
          CreateBy       : '',
          CreateDate     : '',
          UpdateBy       : '',
          UpdateDate     : '',
          IsLocal        : true,
        }
      }
      if (addr) {
        addr.AddressLocation = location.AddressLocation || null
      }
    }
    return addr
  }
)

// </BurnPermitLocation>

// <Person>

export const personByIdSelector = ormByIdSelector(
  ({ Person, }, PersonId) => {
    if (isNaN(PersonId)) {
      return null
    }
    const person = Person.withId(PersonId) 
    if (!person) {
      return null
    }
    const Addresses = person.Addresses.all().toRefArray().map(addressMapper)
    const Phones = person.Phones.all().toRefArray().map(phoneMapper)
    return {
      PersonId         : person.PersonId,
      EmailId          : person.EmailId,
      PersonTypeId     : person.PersonTypeId,
      IsUser           : person.IsUser,
      PersonFirstName  : person.PersonFirstName,
      PersonMiddleName : person.PersonMiddleName,
      PersonLastName   : person.PersonLastName,
      ContactMethodId  : person.ContactMethodId,
      AlertMethodId    : person.AlertMethodId,
      Email            : person.Email,
      CreateBy         : person.CreateBy,
      UpdateBy         : person.UpdateBy,
      CreateDate       : person.CreateDate,
      UpdateDate       : person.UpdateDate,
      Addresses,
      Phones, 
    }
  }
)

// </Person>

// <Address>

export const addressByIdSelector = ormByIdSelector(
  ({ Address, }, AddressId) => {
    if (isNaN(AddressId)) {
      return null
    }
    const address = Address.withId(AddressId) 
    if (!address) {
      return null
    }
    return addressMapper(address)
  }
)

export const addressTypeByNameSelector = ormWithPropSelector(
  ({ AddressType, }, AddressTypeName) => {
    return AddressType.all().filter({ AddressTypeName, }).first()
  }
)

export const addressTypesSelectSelector = ormSelector(
  ({ AddressType, }) => {
    const addrTypes = AddressType.all()
    if (addrTypes) {
      return addrTypes.toModelArray().map((a) => {
        return {
          Value : a.AddressTypeId,
          Text  : a.AddressTypeName,
        }
      })
    }
    return []
  }
)
// </Address>

// <Phone>
export const contactMethodsForSelectSelector = ormSelector(
  ({ ContactMethod, }) => {
    return ContactMethod.all().toModelArray().map((m) => {
      return {
        Value : m.ContactMethodId,
        Text  : m.ContactMethodName,
      }
    })
  }
)

export const phonesByPersonIdForSelectSelector = ormByIdSelector(
  (session, PersonId) => {
    if (isNaN(PersonId)) {
      return []
    }
    const person = session.Person.withId(PersonId)
    if (!person) {
      return []
    }
    if (!person.Phones.exists()) {
      return []
    }
    return person.Phones.all().toModelArray().map((a) => {
      return {
        Value : a.PhoneId,
        Text  : a.PhoneNumber,
      }
    })
  }
)

export const addressesByPersonIdForSelectSelector = ormByIdSelector(
  (session, PersonId) => {
    if (isNaN(PersonId)) {
      return []
    }
    const person = session.Person.withId(PersonId)
    if (!person) {
      return []
    }
    if (!person.Addresses.exists()) {
      return []
    }
    return person.Addresses.toModelArray().map((a) => {
      return {
        Value : a.AddressId,
        Text  : a.toString(),
      }
    })
  }
)

export const phoneTypesSelector = ormSelector(
  session => {
    const phoneTypes = session.PhoneType.all()
    if (phoneTypes) {
      return phoneTypes.toModelArray().map((a) => {
        return {
          Value : a.PhoneTypeId,
          Text  : a.PhoneTypeName,
        }
      })
    }
    return []
  }
)

// </Phone>

// <Email>

export const emailByPersonIdSelector = ormByIdSelector(
  (session, PersonId) => {
    if (isNaN(PersonId)) {
      return null
    }
    const byEmail = session.Email.filter({ PersonId, }).first()
    if (byEmail) {
      return {
        EmailId                : byEmail.EmailId,
        EmailAddress           : byEmail.EmailAddress,
        NormalizedEmailAddress : byEmail.NormalizedEmailAddress,
        EmailConfirmed         : byEmail.EmailConfirmed,
        CreateBy               : byEmail.CreateBy,
        CreateDate             : byEmail.CreateDate,
        UpdateBy               : byEmail.UpdateBy,
        UpdateDate             : byEmail.UpdateDate,
      }
    }
    const person = session.Person.withId(PersonId)
    if (!person) {
      return null
    }
    if (person.Email) {
      return {
        EmailId                : person.Email.EmailId,
        EmailAddress           : person.Email.EmailAddress,
        NormalizedEmailAddress : person.Email.NormalizedEmailAddress,
        EmailConfirmed         : person.Email.EmailConfirmed,
        CreateBy               : person.Email.CreateBy,
        CreateDate             : person.Email.CreateDate,
        UpdateBy               : person.Email.UpdateBy,
        UpdateDate             : person.Email.UpdateDate,
      }
    }
    return null 
  }
)

// </Email>

export const equipmentTypeCheckboxSelector = ormWithPropSelector(
  propsSelector,
  (session, { category, useDesc, }) => {
    let models = session.EquipmentType.all().toModelArray()
    if (category) {
      models = models.filter(e => e.EquipmentTypeCategoryName === category || !e.EquipmentTypeCategoryName)
    }
    return models.map((e) => {
      return {
        Value : e.EquipmentTypeId,
        Text  : useDesc !== true ? e.EquipmentTypeName : e.EquipmentTypeDescription,
      }
    })
  }
)

export const burnIgnitionTypesCheckboxSelector = ormSelector(
  session => {
    return session.BurnIgnitionType.all().toModelArray().map((b) => {
      return {
        Value : b.BurnIgnitionTypeId,
        Text  : b.BurnIgnitionTypeName,
      }
    })
  }
)

export const permitHasPileInfoSelector = ormByIdSelector(
  ({ BurnPermitPileGroup, }, PermitId) => {
    if (isNaN(PermitId)) {
      return false
    }
    return BurnPermitPileGroup.filter({ BurnPermitId: PermitId, }).exists()
  }
)

export const duffTypeSelectSelector = ormSelector(
  ({ DuffType, }) => {
    const duffTypes = DuffType.all()
    if (duffTypes.exists()) {
      return duffTypes.toRefArray().map(d => {
        return {
          Value : d.DuffTypeId,
          Text  : d.DuffTypeName,
        }
      })
    }
    return []
  }
)

export const daysOfWeekCheckboxSelector = ormSelector(
  session => {
    return session.DaysOfWeek.all().toModelArray().map((d) => {
      return {
        Value : d.DayOfWeekId,
        Text  : d.DayOfWeekName,
      }
    })
  }
)

export const fuelDiametersSelector = ormSelector(
  session => {
    return session.FuelDiameter.all().toModelArray()
  }
)

const fuelsBurnTypes = [ 'Broadcast', 'Natural', ]

export const fuelsInfoByIdSelector = ormByIdSelector(
  ({ BurnPermit, BurnPermitFuel, }, FuelsInfoId) => {
    if (isNaN(FuelsInfoId)) {
      return null
    }
    
    const fuel = BurnPermitFuel.withId(FuelsInfoId)
    if (!fuel) {
      return null
    }
    let ShowFuelsCalculator = false
    const permit = BurnPermit.filter({ BurnPermitFuelId: FuelsInfoId, }).first()
    if (permit && permit.BurnPermitArea && permit.BurnPermitArea.BurnTypes) {
      ShowFuelsCalculator = permit.BurnPermitArea.BurnTypes
        .all()
        .toModelArray()
        .some(t => fuelsBurnTypes.includes(t.BurnTypeName))
    }
    const ret = {
      BurnPermitFuelId                 : fuel.BurnPermitFuelId,
      BurnIgnitionTypes                : fuel.BurnIgnitionTypes.toRefArray().map(t => t.BurnIgnitionTypeId) || [],
      OtherBurnIgnitionTypeDescription : fuel.OtherBurnIgnitionTypeDescription || '',
      DuffTypeId                       : getValueOrDefault(fuel.DuffTypeId, ''),
      DuffDepth                        : getValueOrDefault(fuel.DuffDepth, ''),
      LitterDepth                      : getValueOrDefault(fuel.LitterDepth, ''),
      BurnPermitFuelLoadingXref        : fuel.BurnPermitFuelLoadingXrefs.toRefArray() || [],
      Shrubs                           : getValueOrDefault(fuel.Shrubs, ''),
      GrassHerb                        : getValueOrDefault(fuel.GrassHerb, ''),
      ConsumedTonnage                  : fuel.ConsumedTonnage || '',
      TotalTonsPerAcre                 : fuel.TotalTonsPerAcre || '',
      CreateBy                         : fuel.CreateBy,
      CreateDate                       : fuel.CreateDate,
      UpdateBy                         : fuel.UpdateBy,
      UpdateDate                       : fuel.UpdateDate,
      ShowFuelsCalculator,
    }
    return ret
  }
)

export const getBurnPermitId = ormWithPropSelector(
  (session, filterObj) => {
    const burnPermit = session.BurnPermit.filter(filterObj)
    if (burnPermit.exists()) {
      const { BurnPermitId, } = burnPermit.first()
      return BurnPermitId
    }
    return null
  }
)

export const getBurnPermit = ormWithPropSelector(
  (session, filterObj) => {
    const burnPermit = session.BurnPermit.filter(filterObj)
    if (burnPermit.exists()) {
      return burnPermit.first()
    }
    return null
  }
)

export const burnPermitAreaByIdSelector = ormByIdSelector(
  (session, BurnPermitAreaId) => {
    if (isNaN(BurnPermitAreaId)) {
      return null
    }
    const area = session.BurnPermitArea.withId(BurnPermitAreaId)
    if (!area) {
      return null
    }
    return {
      BurnPermitAreaId,
      HarvestAcres : area.HarvestAcres || '',
      BurnTypes    : area.BurnTypes.toRefArray().map(t => t.BurnTypeId) || [],
      CreateBy     : area.CreateBy,
      CreateDate   : area.CreateDate,
      UpdateBy     : area.UpdateBy,
      UpdateDate   : area.UpdateDate,
    }
  }
)

export const burnTypesCheckboxSelector = ormSelector(
  session => {
    return session.BurnType.all().toModelArray().map((b) => {
      return {
        Value : b.BurnTypeId,
        Text  : b.BurnTypeName,
      }
    })
  }
)

export const countySelectSelector = ormSelector(
  session => {
    return session.County.all().toModelArray().map((d) => {
      return {
        Value : d.CountyId,
        Text  : d.CountyName,
      }
    })
  }
)

// <ForestType>

export const forestTypesSelector = ormSelector(
  session => {
    return session.ForestType.all().toModelArray()
  }
)

// </ForestType>


export const getDnrUsersForSelect = ormSelector(
  session => {
    const users = session.ApplicationUser
      .filter(u => DNR_GROUP_SIDS.includes(u.ClaimValue))
      .toModelArray()
      .map(u => {
        return {
          Value : u.PersonId,
          Text  : `${u.FirstName} ${u.LastName}`,
        }
      })
    return users || []
  }
)

export const storedPersonIds = ormSelector(
  session => {
    return session.Person.all().toRefArray().map(p => p.PersonId)
  }
)

export const storedLandownerIds = ormSelector(
  session => {
    return session.Person.all()
      .toModelArray()
      .filter(p => p.PersonType && (p.PersonType.PersonTypeName === PERSON_TYPE_PRIVATE || p.PersonType.PersonTypeName === PERSON_TYPE_GOV_CUST))
      .map(p => p.PersonId)
  }
)

export const storedAgentIds = ormSelector(
  session => {
    return session.Person.all()
      .toModelArray()
      .filter(p => p.PersonType && p.PersonType.PersonTypeName === PERSON_TYPE_AGENT)
      .map(p => p.PersonId)
  }
)

export const newPersonByIdSelector = ormByIdSelector(
  (session, personId) => {
    if (isNaN(personId) || !session.Person.idExists(personId)) {
      return null
    }

    const person = session.Person.withId(personId)

    let Addresses = []
    if (person.Addresses.exists()) {
      Addresses = person.Addresses.all().toRefArray().map(addressMapper)
    }
    let Phones = []
    if (person.Phones.exists()) {
      Phones = person.Phones.all().toRefArray().map(phoneMapper)
    }
    let Region = null
    if (person.PersonRegionXrefs.exists()) {
      // there should only ever be 1
      const xref = person.PersonRegionXrefs.first()
      Region = {
        RegionId              : xref.PersonRegionXrefRegionId,
        RegionDefaultAssignee : xref.RegionDefaultAssignee,
      }  
    }
    let AgencyXref = { AgencyName: '', }
    if (person.PersonAgencyXrefs.exists()) {
      // there should only ever be 1
      const xref = person.PersonAgencyXrefs.first()
      let AgencyName = ''
      if (xref.PersonAgencyXrefAgency) {
        AgencyName = xref.PersonAgencyXrefAgency.AgencyName
      }
      AgencyXref = {
        PersonAgencyXrefId : xref.PersonAgencyXrefId,
        AgencyId           : xref.PersonAgencyXrefAgencyId,
        AgencyName         : AgencyName,
        ConfirmedOn        : xref.ConfirmedOn,
        ConfirmedBy        : xref.ConfirmedBy,
      }
    }
    let alertPrefs = []
    if (Array.isArray(person.PersonAlertPreferenceXref) && person.PersonAlertPreferenceXref.length) {
      alertPrefs = person.PersonAlertPreferenceXref.reduce((acc, x) => {
        const name = `PersonAlertPreferenceXref_${x.AlertPreferenceId}`
        acc[name] = x
        return acc
      }, {})
    }
    
    return {
      PersonId         : person.PersonId,
      EmailId          : person.EmailId,
      IsUser           : person.IsUser,
      ParentId         : person.ParentId,
      PersonTypeId     : person.PersonTypeId || '',
      PersonFirstName  : person.PersonFirstName || '',
      PersonMiddleName : person.PersonMiddleName || '',
      PersonLastName   : person.PersonLastName || '',
      ContactMethodId  : person.ContactMethodId || '',
      CreateBy         : person.CreateBy,
      CreateDate       : person.CreateDate,
      UpdateBy         : person.UpdateBy,
      UpdateDate       : person.UpdateDate,
      Email            : person.Email ? {
        EmailId      : person.Email.EmailId,
        EmailAddress : person.Email.EmailAddress || '',
        CreateBy     : person.Email.CreateBy,
        CreateDate   : person.Email.CreateDate,
        UpdateBy     : person.Email.UpdateBy,
        UpdateDate   : person.Email.UpdateDate,
      } : null,
      Addresses,
      Phones,
      RegionId              : Region ? Region.RegionId : '',
      RegionDefaultAssignee : Region ? Region.RegionDefaultAssignee ? 'yes' : 'no': 'no',
      Region,
      Agency                : AgencyXref,
      ...alertPrefs,
    }
  }
)

export const alertMethodsSelector = ormSelector(
  session => {
    if (!session.AlertMethod.all().exists()) {
      return []
    }
    return session.AlertMethod.all().toRefArray().map((v) => {
      return {
        Value       : v.AlertMethodId,
        Text        : v.AlertMethodName,
        Description : v.AlertMethodDescription,
      }
    })
  }
)

export const alertPreferencesSelect = ormSelector(
  session => {
    if (!session.AlertPreference.all().exists()) {
      return []
    }
    return session.AlertPreference.all().toRefArray().map((v) => {
      return {
        AlertPreferenceId          : v.AlertPreferenceId,
        AlertPreferenceName        : v.AlertPreferenceName,
        AlertPreferenceDescription : v.AlertPreferenceDescription,
      }
    })
  }
)
