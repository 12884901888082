// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { PropTypes, string, number, func, object, array, bool, } from 'prop-types'
import {
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Fade,
  Alert,
  CardHeader,
} from 'reactstrap'
import { isEmpty, } from 'lodash'

// Redux
import BurnPermitSignatureActions from '../../redux/BurnPermitSignatureRedux'
import BurnPermitFormActions from '../../redux/BurnPermitFormRedux'
import ConditionActions from '../../redux/BurnPermitConditionsRedux'
import PersonActions from '../../redux/PersonRedux'

// Components
import DataTable from '../DataTable'
import {
  SuspensionForm,
  RevokePermitForm,
  ExtendPermitForm,
  IssuePermitForm,
  ApplicationStatusForm,
} from '../PermitStatus'
import { ReIssuePermit, } from '../Modals'

// Selectors
import {
  permitStatusHistory,
  permitStatus,
  networkStateSelector,
} from '../../selectors/selectors'
import { isLoadingDocuSignUrl, signatureByPermitId, } from '../../selectors/burnPermitSignatureSelectors'
import {
  userCanSeeInternalOnly,
  userCanEditPermitStatus,
  userCanEditPermitConditions,
} from '../../selectors/burnPermitPermissionSelectors'
import { userNameSelector, } from '../../selectors/userSelectors'
import {
  permitDates,
  permitApplicationStatus,
  permitIsIssued,
  permitIsExpiredSelector,
  permitHasSignedPermitDoc,
} from '../../selectors/burnPermitSelectors'
import { conditionValuesNewForBurnPermitSelector, } from '../../selectors/conditionsSelectors'

// Utilities
import { renderDateTime, renderCheckIcon, } from '../../utilities/columnHelpers'
import { dateFormatter, } from '../../utilities'

// Constants
const DELETE_APPLICATION_STATUS_MODAL_TYPE = 'DELETE_APPLICATION_STATUS'
const DELETE_PERMIT_STATUS_MODAL_TYPE = 'DELETE_PERMIT_STATUS'
const DELETE_ICON = '<span class="fa fa-times-circle" style="color: red; display: block; margin: auto; width: 1 em; cursor: pointer;"/>'


class BurnPermitStatusSection extends React.Component {
  static propTypes = {
    BurnPermitApplicationStatusId : PropTypes.oneOfType([ number, string, ]),
    AssignedToId                  : PropTypes.oneOfType([ number, string, ]),
    IsLoadingDocuSignUrl          : bool,
    // from parent
    isApproved                    : bool,
    burnPermitId                  : number.isRequired,
    isSuspended                   : bool,
    isRevoked                     : bool,
    // actions
    LoadingDocuSignUrl            : func,
    GetAssignableUsers            : func,
    ConditionSetPermit            : func,
    GetBurnPermitStatusHistory    : func.isRequired,
    SubmitApplicationStatus       : func,
    DeleteApplicationStatus       : func,
    DeletePermitStatus            : func,
    ReIssuePermit                 : func,
    ClearSuspension               : func,
    UpdateSuspension              : func,
    RevokePermit                  : func,
    UndoRevocation                : func,
    // selectors
    isGovAgentPermit              : bool,
    ValidDate                     : string,
    ExpirationDate                : string,
    SuspensionStartDate           : string,
    SuspensionEndDate             : string,
    SuspensionComment             : string,
    RevokedDate                   : string,
    RevokedComment                : string,
    dnrUsers                      : array,
    userName                      : string,
    BurnPermitStatusHistory       : array,
    appStatusIsReadOnly           : bool,
    signature                     : object,
    BurnPermitStatusId            : PropTypes.oneOfType([ number, string, ]),
    HasConditionsSet              : bool,
    isIssued                      : bool,
    hasSignedDoc                  : bool,
    isExpired                     : bool,
    ExtendPermit                  : func,
    online                        : bool,
    canViewInternalOnly           : bool,
    canEditPermitStatus           : bool,
    canEditPermitConditions       : bool,
    canComment                    : bool,
    isDnr                         : bool,
  }
  
  get columns () {
    const defs = [ 
      {
        title : 'Status',
        data  : 'Status',
      },
      {
        data   : 'StatusDate',
        title  : 'Status Date',
        render : renderDateTime,
      },
      {
        title          : 'Comment',
        data           : 'Comment',
        defaultContent : '',
      },
      {
        data           : 'SiteInspected',
        title          : 'Site Inspected',
        width          : '105px',
        defaultContent : '',
        render         : renderCheckIcon,
      },
      {
        title          : 'Assigned To',
        data           : 'AssignedTo',
        defaultContent : '',
      }, 
      {
        title          : 'User',
        data           : 'CreateBy',
        defaultContent : '',
      }, 
    ]
    if (this.props.canViewInternalOnly) {
      defs.push({
        data           : 'InternalOnly',
        title          : 'Internal',
        width          : '10px',
        defaultContent : '',
        render         : renderCheckIcon,
      })
      
      if (this.props.isIssued !== true) {
        defs.push({
          data           : 'CreateBy',
          title          : 'Delete',
          width          : '10px',
          className      : 'delete-record',
          defaultContent : '',
          render         : this.deleteButtonCol,
        })
      }
    }
    return defs
  }

  state = {
    redraw            : false,
    editingSuspension : false,
    revokingPermit    : false,
    extendingPermit   : false,
    activeButton      : '',
    modalState        : {
      isModalVisible : false,
      modalType      : '',
      modalData      : null,
    },
  }

  deleteButtonCol = (data, drawType, row) => {
    if (drawType !== 'display') {
      return data
    }
    if (row && row.Status !== 'Pending' && data === this.props.userName && this.props.isIssued !== true) {
      return DELETE_ICON
    } 
    return ''
  }

  showDeleteStatusModal = rowData => {
    if (rowData.BurnPermitApplicationStatusXrefId) {
      this.setState({ 
        modalState: {
          isModalVisible : true,
          modalType      : DELETE_APPLICATION_STATUS_MODAL_TYPE,
          modalData      : {
            BurnPermitApplicationStatusXrefId : rowData.BurnPermitApplicationStatusXrefId,
            Comment                           : rowData.Comment,
            StatusName                        : rowData.Status,
            InternalOnly                      : rowData.InternalOnly,
          },
        },
      })
    }
    else if (rowData.BurnPermitStatusXrefId) {
      this.setState({ 
        modalState: {
          isModalVisible : true,
          modalType      : DELETE_PERMIT_STATUS_MODAL_TYPE,
          modalData      : {
            BurnPermitStatusXrefId : rowData.BurnPermitStatusXrefId,
            Comment                : rowData.Comment,
            StatusName             : rowData.Status,
            InternalOnly           : rowData.InternalOnly,
          },
        },
      })
    }
  }

  getModalHeader = () => {
    const { modalState, } = this.state
    if (!modalState.isModalVisible) {
      return null
    }
    if (modalState.modalType === DELETE_APPLICATION_STATUS_MODAL_TYPE) {
      return 'Deleting Application Status'
    }
    else if (modalState.modalType === DELETE_PERMIT_STATUS_MODAL_TYPE) {
      return 'Deleting Permit Status'
    }
  }

  getModalBody = () => {
    const { modalState, } = this.state
    if (!modalState.isModalVisible) {
      return null
    }
    const { modalData, } = modalState
    let ID = ''
    if (modalState.modalType === DELETE_APPLICATION_STATUS_MODAL_TYPE) {
      ID = modalData.BurnPermitApplicationStatusXrefId
    }
    else if (modalState.modalType === DELETE_PERMIT_STATUS_MODAL_TYPE) {
      ID = modalData.BurnPermitStatusXrefId
    }
    return <>
      <p>Are you sure you want to delete this status?</p>
      <span>ID: {ID}</span><br/>
      <span>Status: {modalData.StatusName}</span><br/>
      <span>Internal Only: {modalData.InternalOnly ? 'Yes' : 'No'}</span><br/>
      <span>Comment: {modalData.Comment}</span>
    </>
  }

  getModalFooter = () => {
    const { modalState, } = this.state
    if (!modalState.isModalVisible) {
      return null
    }
    let clickAction
    if (modalState.modalType === DELETE_APPLICATION_STATUS_MODAL_TYPE) {
      clickAction = this.deleteApplStatusSubmitAction
    }
    else if (modalState.modalType === DELETE_PERMIT_STATUS_MODAL_TYPE) {
      clickAction = this.deletePermitStatusSubmitAction
    }
    return <>
      <Button color={'secondary'} onClick={clickAction}>Delete</Button>
      <Button color={'light'} onClick={this.resetModalState}>Cancel</Button>
    </>
  }

  resetModalState = () => {
    this.setState({
      modalState: {
        isModalVisible : false,
        modalData      : null,
        modalType      : '',
      },
    })
  }

  // We need to request the status history on mount as it affects
  // other parts of the application display
  componentDidMount () {
    const { online, burnPermitId, GetBurnPermitStatusHistory, ConditionSetPermit, LoadingDocuSignUrl, } = this.props
    LoadingDocuSignUrl(false)
    if (online) {
      GetBurnPermitStatusHistory(burnPermitId)
      ConditionSetPermit(burnPermitId)
    }
    const { canEditPermitStatus, GetAssignableUsers, } = this.props
    if (canEditPermitStatus) {
      GetAssignableUsers()
    }
    /**
     * This changes state twice so the DataTable lifecycle methods will get
     * called with every change.
     * If redraw is set to true just once, the next time this method is called,
     * state is changed, and passed as a prop to DataTable, DataTable won't redraw
     * even if the didUpdate method in Datatable is set to only check for the 
     * current props.redraw to be true
    */
    this.setState({ redraw: true, issuedReqs: true, }, () => {
      this.setState({ redraw: false, })
    })
  }

  componentDidUpdate = (prevProps) => {
    const newState = {}
    const { BurnPermitStatusId, BurnPermitApplicationStatusId, isSuspended, } = this.props
    if (!isNaN(parseInt(BurnPermitStatusId)) && prevProps.BurnPermitStatusId !== BurnPermitStatusId) {
      newState.BurnPermitStatusId = BurnPermitStatusId
      newState.modalState = {
        isModalVisible : false,
        modalData      : null,
        modalType      : '',
      }
    }
    if (!isNaN(parseInt(BurnPermitApplicationStatusId)) && prevProps.BurnPermitApplicationStatusId !== BurnPermitApplicationStatusId) {
      newState.BurnPermitApplicationStatusId = BurnPermitApplicationStatusId
      newState.modalState = {
        isModalVisible : false,
        modalData      : null,
        modalType      : '',
      }
    }
    if (prevProps.isSuspended && !isSuspended) {
      newState.editingSuspension = false
    }
    if (!isEmpty(newState)) {
      this.setState(newState)
    }
  }

  validateSection = () => {
    if (this.formik.isValid) {
      this.formik.submitForm()
    }
    return this.formik.isValid
  }

  deleteApplStatusSubmitAction = () => {
    const { burnPermitId, } = this.props
    const { BurnPermitApplicationStatusXrefId, } = this.state.modalState.modalData
    this.props.DeleteApplicationStatus(burnPermitId, BurnPermitApplicationStatusXrefId)
    this.resetModalState()
  }

  deletePermitStatusSubmitAction = () => {
    const { burnPermitId, } = this.props
    const { BurnPermitStatusXrefId, } = this.state.modalState.modalData
    this.props.DeletePermitStatus(burnPermitId, BurnPermitStatusXrefId)
    this.resetModalState()
  }

  reIssuePermit = () => {
    this.props.ReIssuePermit(this.props.burnPermitId)
  }

  endSuspension = () => {
    const { burnPermitId, } = this.props
    if (!this.props.canEditPermitStatus || !burnPermitId) {
      return
    }
    this.props.ClearSuspension(burnPermitId)
  }

  saveSuspension = (values) => {
    if (!values) {
      return 
    }
    const { burnPermitId, } = this.props
    const { SuspensionStartDate, SuspensionEndDate, SuspensionComment, } = values
    if (!this.props.canEditPermitStatus || !burnPermitId || (!SuspensionStartDate && !SuspensionEndDate)) {
      return
    }
    this.props.UpdateSuspension(burnPermitId, SuspensionStartDate, SuspensionEndDate, SuspensionComment)
  }

  undoRevocation = values => {
    const { UndoRevocation, burnPermitId, } = this.props
    UndoRevocation(burnPermitId, values)
    this.toggleRevokingPermit()
  }

  revokePermit = values => {
    const { RevokePermit, burnPermitId, } = this.props
    RevokePermit(burnPermitId, values)
    this.toggleRevokingPermit()
  }

  toggleRevokingPermit = () => {
    const { revokingPermit, } = this.state
    this.setState({ revokingPermit: !revokingPermit, activeButton: !revokingPermit ? 'revoke' : '', })
  }

  extendPermit = values => {
    const { ExtendPermit, burnPermitId, } = this.props
    const { ExpirationDate, } = values
    ExtendPermit(burnPermitId,ExpirationDate)
    this.toggleExtendingPermit()
  }
  
  toggleExtendingPermit = () => {
    const { extendingPermit, } = this.state
    this.setState({ extendingPermit: !extendingPermit, activeButton: !extendingPermit ? 'extend' : '', })
  }

  toggleSuspension = () => {
    const { editingSuspension, } = this.state
    this.setState({ editingSuspension: !editingSuspension, activeButton: !editingSuspension ? 'suspend' : '', })
  }

  issuePermitControl = () => {
    const { signature, burnPermitId, } = this.props
    let markup = null
    
    if (!signature) {
      return markup
    }
    
    if (signature.PermitEnvelopeIsExpired === true) {
      markup = <>
        <Alert color={'danger'}>
          <b>
            The DocuSign envelope for this Permit became void on {dateFormatter(signature.PermitEnvelopeExpiration, 'MM/DD/YYYY')}
            &nbsp;because the applicant has not signed the Permit in DocuSign.
          </b>
          <p>
            Click <b>Re-Issue Permit</b> below to re-issue their permit.
          </p>
        </Alert>
        <ReIssuePermit burnPermitId={burnPermitId} />
      </>
    }
    else if (signature.PermitEnvelopeExpiringSoon === true) {
      markup = <Alert color={'danger'}>
        <b>
          The DocuSign envelope for this Permit will become void on {dateFormatter(signature.PermitEnvelopeExpiration, 'MM/DD/YYYY')}
          &nbsp;because the applicant has not signed the Permit in DocuSign.
        </b>
        <p>
          Contact the Permitee immediately to help them sign the Permit via DocuSign
          or Extend the Permit.
        </p>
      </Alert>
    }
    return <Col xs={12}>{markup}</Col>
  }

  buildPermitAdminControls = () => {
    const {
      signature,
      isIssued,
      hasSignedDoc,
      isExpired,
      isRevoked,
      isSuspended,
      online,
      canEditPermitStatus,
    } = this.props
    const { editingSuspension, revokingPermit, extendingPermit, activeButton, } = this.state
    const showSuspendButton = canEditPermitStatus && (isIssued || isExpired) && hasSignedDoc && !isSuspended && !isRevoked
    if (!canEditPermitStatus) {
      return null
    }
    return <Col>
      <p>Use these buttons to perform actions related to managing the status of the Burn Permit.</p>
      { !online && <p><b>These actions require an internet connection and will be enabled once a connection is restored.</b></p> }
      <Row>
        {
          signature.PermitEnvelopeIsExpired !== true &&
          <Col xs={12} className={'d-flex justify-content-between'}>
            {
              showSuspendButton && (!!signature.PermitApproverSignedBy || isExpired) && <>
                <Button
                  size={'md'}
                  onClick={this.toggleExtendingPermit}
                  className={'mb-2'}
                  color={'primary'}
                  disabled={!online || (activeButton !== '' && activeButton !== 'extend')}
                >
                  {extendingPermit ? 'Cancel' : 'Extend'}
                </Button>
                <Button
                  size={'md'}
                  color={'warning'}
                  className={'mb-2'}
                  onClick={this.toggleSuspension}
                  disabled={!online || (activeButton !== '' && activeButton !== 'suspend')}
                >
                  {editingSuspension ? 'Cancel' : 'Suspend'}
                </Button>
              </>
            }
            {
              isIssued && !!signature.PermitApproverSignedBy &&
                <Button
                  size={'md'}
                  onClick={this.toggleRevokingPermit}
                  className={'mb-2'}
                  color={'danger'}
                  disabled={!online || (activeButton !== '' && activeButton !== 'revoke')}
                >
                  {revokingPermit ? 'Cancel' : 'Revoke'}
                </Button>
            }
          </Col>
        }
        { this.issuePermitControl() }
      </Row>
    </Col>
  }

  buildPermitRelatedLinks = () => {
    const {
      isIssued,
      burnPermitId,
      HasConditionsSet,
      canEditPermitConditions,
      hasSignedDoc,
    } = this.props
    let conditionsLinkText = 'View'
    const permitIsBurnable = isIssued && hasSignedDoc
    if (!isIssued && canEditPermitConditions) {
      conditionsLinkText = HasConditionsSet
        ? 'Edit'
        : 'Add'
    }
    return <Col>
      <p>Use these links to view additional data related to the Burn Permit.</p>
      <Row>
        <Col>
          <Link
            className={'btn btn-link mr-2'}
            to={`/permits/${burnPermitId}/conditions`}
          >
            {conditionsLinkText} Permit Conditions
          </Link>
          {
            permitIsBurnable &&
            <Link
              className={'btn btn-link mr-2'}
              to={`/permits/${burnPermitId}/postburn`}
            >
              Enter Post-Burn Data
            </Link>
          }
        </Col>
      </Row>
    </Col>
  }

  render () {
    const {
      isIssued,
      isApproved,
      isSuspended,
      isRevoked,
      appStatusIsReadOnly,
      signature,
      online,
      canEditPermitStatus,
      canComment,
    } = this.props

    const {
      editingSuspension,
      revokingPermit,
      extendingPermit,
    } = this.state

    const showApplStatusComments = (canEditPermitStatus || canComment) && !isIssued
    const showPermitDurationRadioButtons = canEditPermitStatus && isApproved && !isSuspended && !isRevoked && !signature.PermitApproverSignedBy

    return (
      <>
        { isApproved &&
          <Col xs={'12'} className={'mb-3'}>
            <Card>
              <CardBody>
                <Row>
                  {this.buildPermitRelatedLinks()}
                  {this.buildPermitAdminControls()}
                </Row>
                <Fade in={(editingSuspension || isSuspended)}>
                  <hr className={!(editingSuspension || isSuspended) ? 'm-0' : ''} />
                  <Row className={'mt-2'}>
                    <Col>
                      <SuspensionForm
                        suspensionStartDate={this.props.SuspensionStartDate}
                        suspensionEndDate={this.props.SuspensionEndDate}
                        suspensionComment={this.props.SuspensionComment}
                        isSuspended={isSuspended}
                        onSubmit={this.saveSuspension}
                        onEndSuspension={this.endSuspension}
                        readOnly={!online || !canEditPermitStatus}
                      />
                    </Col>
                  </Row>
                </Fade>
                <Fade in={revokingPermit}>
                  <hr className={!revokingPermit ? 'm-0' : ''} />
                  <Row className={'mt-2'}>
                    <Col>
                      <RevokePermitForm
                        RevokedDate={this.props.RevokedDate}
                        Comment={this.props.RevokedComment}
                        isRevoked={isRevoked}
                        onSubmit={this.revokePermit}
                        onUndo={this.undoRevocation}
                        readOnly={!online || !canEditPermitStatus}
                      />
                    </Col>
                  </Row>
                </Fade>
                <Fade in={extendingPermit}>
                  <hr className={!extendingPermit ? 'm-0' : ''} />
                  <Row className={'mt-2'}>
                    <Col>
                      <ExtendPermitForm
                        onSubmit={this.extendPermit}
                        readOnly={!online || !canEditPermitStatus || !extendingPermit}
                        ValidDate={this.props.ValidDate}
                      />
                    </Col>
                  </Row>
                </Fade>
              </CardBody>
            </Card>
          </Col>
        }
        {
          !appStatusIsReadOnly &&
            <Col xs={12}>
              <Row>
                {
                  showApplStatusComments && 
                    <Col xs={12} md={isApproved ? 6 : 12} className={'mb-3'}>
                      <Card className={'h-100'}>
                        <CardHeader tag={'h4'}>Application Status</CardHeader>
                        <CardBody>
                          <div className={'h-100 d-flex flex-column justify-content-between'}>
                            <ApplicationStatusForm
                              burnPermitId={this.props.burnPermitId}
                              isIssued={isIssued}
                              canEditPermitStatus={canEditPermitStatus}
                              appStatusIsReadOnly={appStatusIsReadOnly}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                }
                { isApproved &&
                  <Col xs={12} md={showApplStatusComments ? 6 : 12} className={'mb-3'}>
                    <Card className={'h-100'}>
                      <CardHeader tag={'h4'}>Permit Status</CardHeader>
                      <CardBody>
                        <div className={'h-100 d-flex flex-column justify-content-between'}>
                          <IssuePermitForm
                            burnPermitId={this.props.burnPermitId}
                            ValidDate={this.props.ValidDate}
                            ExpirationDate={this.props.ExpirationDate}
                            isIssued={isIssued}
                            readOnly={!online || !canEditPermitStatus || !!signature.PermitApproverSignedBy}
                            showPermitDurationRadioButtons={showPermitDurationRadioButtons}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                }
              </Row>
            </Col>
        }
        <Col xs={12} className={'mb-3'}>
          <Card>
            <CardBody style={{ padding: '0.75em', }}>
              <DataTable
                columns={this.columns}
                ordering={false}
                pageLength={5}
                disablePageLengthChange={true}
                disableRowClick={true}
                records={this.props.BurnPermitStatusHistory}
                elementId={'burn-permit-status-history-table'}
                redraw={this.state.redraw}
                onDeleteRecordClicked={this.showDeleteStatusModal}
              />
            </CardBody>
          </Card>
        </Col>
        <Modal isOpen={this.state.modalState.isModalVisible}>
          {
            this.state.modalState.isModalVisible && <>
              <ModalHeader>{this.getModalHeader()}</ModalHeader>
              <ModalBody>{this.getModalBody()}</ModalBody>
              <ModalFooter>{this.getModalFooter()}</ModalFooter>
            </>
          }
        </Modal>
      </>
    )
  }
}

function mapStateToProps (state, props) {
  const { burnPermitId, isDnr, isRevoked, } = props // By default, always get the state object
  
  const { 
    ValidDate = '', 
    ExpirationDate = '', 
    SuspensionStartDate = '', 
    SuspensionEndDate = '',
    SuspensionComment = '',
    RevokedDate = '',
    RevokedComment = '',
  } = permitDates(state, burnPermitId)

  const isExpired = permitIsExpiredSelector(state)

  const { online, } = networkStateSelector(state)
  const stateObj = {
    online,
    ValidDate,
    ExpirationDate,
    SuspensionStartDate,
    SuspensionEndDate,
    SuspensionComment,
    RevokedDate,
    RevokedComment,
    isExpired,
    isRevoked,
    IsLoadingDocuSignUrl    : isLoadingDocuSignUrl(state),
    dnrUsers                : [],
    userName                : userNameSelector(state),
    BurnPermitStatusHistory : permitStatusHistory(state, burnPermitId),
    canViewInternalOnly     : userCanSeeInternalOnly(state),
    canEditPermitStatus     : userCanEditPermitStatus(state),
    canEditPermitConditions : userCanEditPermitConditions(state),
    canComment              : false,
    isIssued                : permitIsIssued(state, burnPermitId),
    isApproved              : false,
    isDenied                : false,
    appStatusIsReadOnly     : false,
  }
  
  let status = permitStatus(state, burnPermitId)
  if (!status) {
    status = permitApplicationStatus(state, burnPermitId)
    if (status) {
      stateObj.isApproved = status.Status === 'Approved'
      stateObj.isDenied = status.Status === 'Denied'
      stateObj.appStatusIsReadOnly = status.Status === 'Pending'
      if (!isDnr) {
        stateObj.appStatusIsReadOnly = status.Status !== 'Info Required'
        stateObj.canComment = !stateObj.appStatusIsReadOnly
      }
    }
  }
  else {
    // Assume it's approved if there is a permit status
    stateObj.isApproved = true
    stateObj.appStatusIsReadOnly = stateObj.isIssued || isRevoked || isExpired
    stateObj.canEditPermitConditions = !stateObj.appStatusIsReadOnly
  }

  // If it's approved
  if (stateObj.isApproved) {
    // Get the Permit Status and Statuses
    const signature = signatureByPermitId(state, props.burnPermitId)
    const hasSignedDoc = permitHasSignedPermitDoc(state, props.burnPermitId)
    stateObj.hasSignedDoc = hasSignedDoc
    stateObj.signature = signature
    stateObj.BurnPermitStatusId = status ? status.BurnPermitStatusId : ''
  }
  const permitConditionValues = conditionValuesNewForBurnPermitSelector(state)
  stateObj.HasConditionsSet = !isEmpty(permitConditionValues)
  
  stateObj.online = state.offline.online

  return stateObj
}

const mapDispatchToProps = {
  GetAssignableUsers         : PersonActions.getAssignableUsers,
  GetBurnPermitStatusHistory : BurnPermitFormActions.getBurnPermitStatusHistory,
  SubmitApplicationStatus    : BurnPermitFormActions.submitApplicationStatus,
  DeleteApplicationStatus    : BurnPermitFormActions.deleteApplicationStatus,
  DeletePermitStatus         : BurnPermitFormActions.deletePermitStatus,
  ExtendPermit               : BurnPermitFormActions.extendPermit,
  LoadingDocuSignUrl         : BurnPermitSignatureActions.loadingDocuSignUrl,
  ReIssuePermit              : BurnPermitSignatureActions.reIssuePermit,
  UpdateSuspension           : BurnPermitFormActions.updateSuspension,
  ClearSuspension            : BurnPermitFormActions.clearSuspension,
  RevokePermit               : BurnPermitFormActions.revokePermit,
  UndoRevocation             : BurnPermitFormActions.undoRevocation,
  ConditionSetPermit         : ConditionActions.setPermitForConditions,
}

export default connect(mapStateToProps, mapDispatchToProps)(BurnPermitStatusSection)