import { createSelector, } from 'reselect'

import {
  userSelector,
} from './userSelectors'
import {
  ormSelector,
  ormWithPropSelector,
  ormByIdSelector,
  permitByIdSelector,
  permitStatusHistory,
  propsSelector,
} from './selectors'
import { agencyPaysByVoucherByPermitId, } from './agencySelectors'
import { permitNeedsForestHealthDoc, } from './forestHealthSelectors'
import { userMustSignIssuedPermit, } from './burnPermitPermissionSelectors'
import { signatureByPermitId, } from './burnPermitSignatureSelectors'

import { dateFormatter, getNow, isAfter, } from '../utilities'
import { formatLegalDesc, } from '../utilities/columnHelpers'

import { GetPermitSymbolByStatus, } from '../config/map/symbols'
import { BURN_PERMIT_STATUS_POINT, } from '../config/map/renderers'
import { PERSON_TYPE_GOV_AGENT, PERSON_TYPE_AGENT, } from './env'

import { baseAppState, } from '../redux/BurnPermitFormRedux'

export const burnPermitStateSelector = state => state.BurnPermitForm

export const permitSearchSelector = ormSelector(
  ({ BurnPermitSearch, }) => {
    return BurnPermitSearch.all().toRefArray()
  }
)

export const permitSearchByIdSelector = ormByIdSelector(
  ({ BurnPermitSearch, }, BurnPermitId) => {
    return BurnPermitSearch.withId(BurnPermitId)
  }
)

export const activeBurnPermitIdSelector = createSelector(
  burnPermitStateSelector,
  state => state ? state.activeBurnPermitId : null
)

const burnPermitSearchParamSelector = createSelector(
  state => state && state.BurnPermitSearch ? state.BurnPermitSearch.searchParams : null
)

export const burnPermitSearchDataSelector = (state) => {
  if (state && state.BurnPermitSearch) {
    return state.BurnPermitSearch
  } 
  return {}
}

export const activeBurnPermitStateSelector = createSelector(
  burnPermitStateSelector,
  activeBurnPermitIdSelector,
  (permitState, activePermitId) => {
    if (isNaN(activePermitId) || !permitState) {
      return {
        activeBurnPermitId : activePermitId || '',
        permitFailedToLoad : false,
        ...baseAppState,
      }
    }
    
    let app = permitState.applications[activePermitId]
    if (!app || !Object.keys(app).length) {
      app = { ...baseAppState, }
    }
    
    return {
      activeBurnPermitId          : permitState.activeBurnPermitId,
      permitFailedToLoad          : permitState.permitFailedToLoad,
      applicationSummaryIsShowing : permitState.applicationSummaryIsShowing,
      ...app,
    }
  }
)

export const activeBurnPermitSelector = ormSelector(
  activeBurnPermitIdSelector,
  ({ BurnPermit, }, activePermitId) => {
    if (isNaN(activePermitId) || !BurnPermit) {
      return null
    }
    return BurnPermit.withId(activePermitId)
  }
)

export const permitsOfflineSelector = ormSelector(
  burnPermitSearchParamSelector,
  ({ BurnPermitSearch, }, searchParams) => {
    let results = BurnPermitSearch
    searchParams = searchParams || {}
    const {
      PermitId,
      BurnPermitNumber,
      AgentName,
      LandownerName,
      BurnType,
      BurnPermitApplicationStatus,
      BurnPermitStatus,
      MinAcres,
      MaxAcres,
      MinTons,
      MaxTons,
      UnitName,
      County,
      Region,
      Address,
      LegalDesc,
      AssignedTo,
    } = searchParams

    if (PermitId) {
      results = results.filter({ BurnPermitId: parseInt(PermitId), })
    }

    if (BurnPermitNumber) {
      results = results.filter(r => r.BurnPermitNumber && r.BurnPermitNumber.toLocaleLowerCase().includes(BurnPermitNumber.toLocaleLowerCase()))
    }

    if (AgentName) {
      results = results.filter(r => r.Agent && r.Agent.toLocaleLowerCase().includes(AgentName.toLocaleLowerCase()))
    }

    if (LandownerName) {
      results = results.filter(r => r.Landowner && r.Landowner.toLocaleLowerCase().includes(LandownerName.toLocaleLowerCase()))
    }

    if (BurnType) {
      results = results.filter({ BurnType, })
    }

    if (BurnPermitApplicationStatus) {
      results = results.filter({ BurnPermitApplicationStatus, })
    }

    if (BurnPermitStatus) {
      results = results.filter({ BurnPermitStatus, })
    }

    if (AssignedTo) {
      results = results.filter({ AssignedTo, })
    }

    if (UnitName) {
      results = results.filter(r => r.BurnUnitName && r.BurnUnitName.toLocaleLowerCase().includes(UnitName.toLocaleLowerCase()))
    }

    if (County) {
      results = results.filter({ County, })
    }

    if (Region) {
      results = results.filter({ Region, })
    }

    if (Address) {
      results = results.filter(r => 
        [
          `${r.Address1 || ''} ${r.Address2 || ''} ${r.Address3 || ''}`.trim(),
          `${r.AddressCity ? ` ${r.AddressCity},` : ''} ${r.AddressState} ${r.AddressZip || ''}`.trim(),
        ].join(' ').toLocaleLowerCase().includes(Address.toLocaleLowerCase()))
    }

    if (LegalDesc) {
      results = results.filter(r => 
        `${r.LegalDescQ1 || ''} ${r.LegalDescQ2 || ''} S${r.LegalDescSection} T${r.LegalDescTownship} R${r.LegalDescRange} ${r.LegalDescDirection || ''}`
          .trim().toLocaleLowerCase().includes(LegalDesc.toLocaleLowerCase())
      )
    }

    if (MinAcres) {
      results = results.filter(r => r.BurnAcres >= MinAcres)
    }

    if (MaxAcres) {
      results = results.filter(r => r.BurnAcres <= MaxAcres)
    }

    if (MinTons) {
      results = results.filter(r => r.TotalPermitTonnage >= MinTons)
    }

    if (MaxTons) {
      results = results.filter(r => r.TotalPermitTonnage <= MaxTons)
    }

    return results.all().toRefArray()
  }
)

export const permitApplicationIsSigned = ormByIdSelector(
  ({ BurnPermitSignature, BurnPermitDocument, }, PermitId) => {
    if (isNaN(PermitId)) {
      return false
    }
    const id = parseInt(PermitId)

    const signature = BurnPermitSignature.filter({ BurnPermitId: id, }).first()
    let isSigned = false
    let hasSignature = !!signature
    if (hasSignature) {
      // Only count signature as being signed if there is an envelopeId and an ApplicantSignedBy
      isSigned = !!signature.ApplicationEnvelopeId && !!signature.ApplicationSignedBy
      if (!isSigned) {
        // Or check if it was signed by DNR and there is a mailed application uploaded
        isSigned = BurnPermitDocument
          .filter({ BurnPermitId: id, })
          .toModelArray()
          .some(doc =>
            doc.BurnPermitDocumentType
            && doc.BurnPermitDocumentType.BurnPermitDocumentTypeName === 'Mailed Application'
          )
      }
    }
    return isSigned
  }
)

export const permitApplicationSignedByDnr = ormByIdSelector(
  ({ BurnPermitSignature, BurnPermitDocument, }, PermitId) => {
    if (isNaN(PermitId)) {
      return false
    }
    const id = parseInt(PermitId)

    const signature = BurnPermitSignature.filter({ BurnPermitId: id, }).first()
    let signedByDnr = false
    let hasSignature = !!signature
    if (hasSignature) {
      // check if it was signed by DNR and there is a mailed application uploaded
      signedByDnr = BurnPermitDocument
        .filter({ BurnPermitId: id, })
        .toModelArray()
        .some(doc =>
          doc.BurnPermitDocumentType
          && doc.BurnPermitDocumentType.BurnPermitDocumentTypeName === 'Mailed Application'
        )
    }
    return signedByDnr
  }
)

const latestAppStatusName = ormByIdSelector(
  activeBurnPermitIdSelector,
  ({ BurnPermitApplicationStatusXref, }, BurnPermitId, activePermitId) => {
    BurnPermitId = parseInt(BurnPermitId || activePermitId)
    if (isNaN(BurnPermitId)) {
      return false
    }
    const statusXref = BurnPermitApplicationStatusXref
      .filter(x => x.BurnPermitId === BurnPermitId)
      .orderBy('CreateDate', 'desc')
      .first()
    if (!statusXref) {
      return false
    }
    return statusXref.BurnPermitApplicationStatus.BurnPermitApplicationStatusName
  }
)

const latestAppStatusNameByPermitId = ormByIdSelector(
  ({ BurnPermitApplicationStatusXref, }, BurnPermitId) => {
    BurnPermitId = parseInt(BurnPermitId)
    if (isNaN(BurnPermitId)) {
      return false
    }
    const statusXref = BurnPermitApplicationStatusXref
      .filter(x => x.BurnPermitId === BurnPermitId)
      .orderBy('CreateDate', 'desc')
      .first()
    if (!statusXref || !statusXref.BurnPermitApplicationStatus) {
      return false
    }
    return statusXref.BurnPermitApplicationStatus.BurnPermitApplicationStatusName
  }
)

const permitIsPending = createSelector(
  latestAppStatusName,
  statusName => {
    return statusName === 'Pending'
  }
)

const permitIsSubmitted = createSelector(
  latestAppStatusName,
  statusName => {
    return statusName === 'Submitted'
  }
)

export const permitByIdIsSubmitted = createSelector(
  latestAppStatusNameByPermitId,
  statusName => {
    return statusName === 'Submitted'
  }
)

export const permitIsApproved = createSelector(
  latestAppStatusName,
  statusName => {
    return statusName === 'Approved'
  }
)

export const permitIsDenied = createSelector(
  latestAppStatusName,
  statusName => {
    return statusName === 'Denied'
  }
)

export const permitIsIssued = ormByIdSelector(
  ({ BurnPermitStatusXref, }, BurnPermitId) => {
    BurnPermitId = parseInt(BurnPermitId)
    if (isNaN(BurnPermitId)) {
      return false
    }
    return BurnPermitStatusXref.filter({ BurnPermitId, }).exists()
  }
)

export const permitIsExtended = ormByIdSelector(
  ({ BurnPermitStatusXref, }, BurnPermitId) => {
    BurnPermitId = parseInt(BurnPermitId)
    if (isNaN(BurnPermitId)) {
      return false
    }
    const latestPermitStatus = BurnPermitStatusXref
      .filter({ BurnPermitId, })
      .orderBy('BurnPermitStatusXrefId', 'desc')
      .first()
    return latestPermitStatus 
      && latestPermitStatus.BurnPermitStatus
      && latestPermitStatus.BurnPermitStatus.BurnPermitStatusName === 'Extended'
  }
)

export const permitApplicationIsPaid = ormByIdSelector(
  ({ BurnPermitOrderXref, }, BurnPermitId) => {
    BurnPermitId = parseInt(BurnPermitId)
    const orderExists = BurnPermitOrderXref.filter({ BurnPermitId, }).toModelArray().some(o => !!o.BurnPermitOrder)
    return orderExists
  }
)

export const permitHasPermitSignatures = ormByIdSelector(
  ({ BurnPermitSignature, }, PermitId) => {
    if (isNaN(PermitId)) {
      return { agency: false, applicant: false, }
    }

    const signature = BurnPermitSignature.all().filter({ BurnPermitId: PermitId, }).first()
    if (signature) {
      return { 
        dnrSubmission : signature.ApplicationSignedBy && !signature.ApplicationEnvelopeId ? true : false,
        agency        : signature.PermitApproverSignedBy ? true : false,
        applicant     : signature.PermitApplicantSignedBy ? true : false,
      }
    }
    return { agency: false, applicant: false, }
  }
)

export const permitPeopleIds = ormByIdSelector(
  permitByIdSelector,
  (orm, PermitId, permit) => {
    if (isNaN(PermitId) || !permit) {
      return {
        BurnerId : null,
        AgentId  : null,
      }
    }
    return {
      BurnerId : permit.BurnerId,
      AgentId  : permit.AgentId,
    }
  }
)

/**
 * Returns the Burner Id of the Permit if found with the provided `PermitId`
 * @param {Object} state 
 * @param {Number} PermitId
 * @returns {Number}
 */
export const permitBurnerId = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => {
    if (!permit) {
      return null
    }
    return permit.BurnerId
  }
)

export const permitEstimatedFee = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => {
    if (!permit) {
      return ''
    }
    return permit.BurnPermitEstimatedFee || ''
  }
)

export const permitTotalPermitTonnage = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => {
    if (!permit) {
      return ''
    }
    return permit.TotalPermitTonnage || ''
  }
)

export const permitDaysSelector = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return []
    }

    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit || !permit.BurnPermitSite) {
      return []
    }

    const days = permit.BurnPermitSite.DaysOfWeek.toModelArray()
    const dayIds = days.map(d => d.DayOfWeekId)
    return dayIds
  }
)

export const permitApplicationDocuments = ormByIdSelector(
  permitStatusHistory,
  (session, BurnPermitId, statusHistory) => {
    const { BurnPermit, } = session
    if (isNaN(BurnPermitId)) {
      return []
    }

    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit) {
      return []
    }

    let latestStatus, submittedStatus, approvedStatus, ReadOnly
    if (Array.isArray(statusHistory) && statusHistory.length) {
      latestStatus = statusHistory[0]
      // if the permit is expired or revoked, or the application is denied, all docs are readonly
      ReadOnly = isAfter(permit.ExpirationDate, (new Date()).toJSON()) || latestStatus.Status === 'Revoked' || latestStatus.Status === 'Denied'
      submittedStatus = statusHistory.filter(h => h.Status === 'Submitted')[0]
      approvedStatus = statusHistory.filter(h => h.Status === 'Approved')[0]
    }

    const docs = permit.BurnPermitDocument.toModelArray()
      .map(d => {
        let docIsReadOnly = ReadOnly
        // if it's not readonly, check if the document is readonly or not
        if (!docIsReadOnly) {
          if (d.BurnPermitDocumentType && d.BurnPermitDocumentType.InternalUseOnly) {
            docIsReadOnly = d.BurnPermitDocumentType.InternalUseOnly
          }
          else if (latestStatus) {
            const { Status, } = latestStatus
            // If it's Issued or Approved, compare to the Approved date
            if (approvedStatus) {
              docIsReadOnly = d.CreateDate < approvedStatus.StatusDate
            }
            else if (submittedStatus && (Status === 'Submitted' || Status === 'Under Review' || Status === 'Info Required')) {
              docIsReadOnly = d.CreateDate < submittedStatus.StatusDate
            }
            else {
              docIsReadOnly = false
            }
          }
        }
        return {
          BurnPermitDocumentId     : d.BurnPermitDocumentId,
          DocumentName             : d.DocumentName,
          BurnPermitDocumentTypeId : d.BurnPermitDocumentTypeId,
          CreateBy                 : d.CreateBy,
          CreateDate               : d.CreateDate,
          UpdateBy                 : d.UpdateBy,
          UpdateDate               : d.UpdateDate,
          ReadOnly                 : docIsReadOnly,
        }
      })
    return docs
  }
)

/**
 * Returns an array containing either a document of type Mailed Application
 * or the Signed Application and Signed Application Certificate documents
 */
export const applicationSignatureDocuments = ormByIdSelector(
  permitStatusHistory,
  ({ BurnPermitDocument, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return []
    }
    const docs = BurnPermitDocument
      .filter(d =>
        d.BurnPermitId === BurnPermitId
        && d.DocumentName.includes('Application')
      )
      .orderBy('CreateDate', 'desc')
    const app = docs.filter(d => d.DocumentName.includes('Certificate') === false).first()
    const cert = docs.filter(d => d.DocumentName.includes('Certificate')).first()
    return [ app, cert, ]
  }
)

/**
 * Returns an array containing the Signed Permit and Signed Permit Certificate documents
 */
export const permitSignatureDocuments = ormByIdSelector(
  permitStatusHistory,
  ({ BurnPermitDocument, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return []
    }
    const docs = BurnPermitDocument
      .all()
      .toModelArray()
      .filter(d =>
        d.BurnPermitId === BurnPermitId
        && d.BurnPermitDocumentType
        && d.BurnPermitDocumentType.BurnPermitDocumentTypeName.includes('Permit')
      )
    let app = null, cert = null
    if (docs.length) {
      app = docs.filter(d =>
        d.BurnPermitDocumentType
        && d.BurnPermitDocumentType.BurnPermitDocumentTypeName.includes('Certificate') === false
      )[0]
      cert = docs.filter(d =>
        d.BurnPermitDocumentType
        && d.BurnPermitDocumentType.BurnPermitDocumentTypeName.includes('Certificate')
      )[0]
    }
    return [ app, cert, ]
  }
)

export const documentTypesForSelect = ormSelector(
  ({ BurnPermitDocumentType, }) => {
    const docTypes = BurnPermitDocumentType.all()
    return docTypes.toRefArray()
      .map(t => {
        return {
          Value       : t.BurnPermitDocumentTypeId,
          Text        : t.BurnPermitDocumentTypeName,
          Description : t.BurnPermitDocumentTypeDescription,
          ReadOnly    : t.InternalUseOnly,
        }
      })
  }
)

const pileTypeSelected = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return false
    }

    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit || !permit.BurnPermitArea) {
      return false
    }

    return permit.BurnPermitArea.BurnTypes
      .all()
      .toRefArray()
      .some(t => t.BurnTypeName === 'Pile')
  }
)

const permitNeedsTonnageCalculationDoc = ormByIdSelector(
  pileTypeSelected,
  ({ BurnPermit, BurnPermitDocument, }, BurnPermitId, hasPileTypeSelected) => {
    if (!hasPileTypeSelected) {
      return hasPileTypeSelected
    }

    const permit = BurnPermit.withId(BurnPermitId)
    const requiresTonnageDoc = permit.BurnPermitArea && permit.BurnPermitArea.HarvestAcres >= 10

    if (requiresTonnageDoc) {
      const hasTonnageCalculationDoc = BurnPermitDocument.filter({ BurnPermitId, }).toModelArray()
        .some(d =>
          d.BurnPermitDocumentType
          && d.BurnPermitDocumentType.BurnPermitDocumentTypeName === 'Tonnage Calculations'
        )

      return !hasTonnageCalculationDoc
    }

    return false
  }
)

const permitNeedsPileLocDoc = ormByIdSelector(
  pileTypeSelected,
  ({ BurnPermit, BurnPermitDocument, }, BurnPermitId, hasPileTypeSelected) => {
    if (!hasPileTypeSelected) {
      return hasPileTypeSelected
    }

    const permit = BurnPermit.withId(BurnPermitId)


    let requiresPileLocationMap = false
    if (permit.BurnPermitArea) {
      const hasMultipleBurnTypes = permit.BurnPermitArea.BurnTypes.all().toRefArray().some(t => t.BurnTypeName !== 'Pile')
      if (permit.BurnPermitArea.HarvestAcres >= 10 || hasMultipleBurnTypes) {
        requiresPileLocationMap = true
      }
    }

    if (requiresPileLocationMap) {
      const hasPileLocationMap = BurnPermitDocument.filter({ BurnPermitId, }).toModelArray()
        .some(d =>
          d.BurnPermitDocumentType &&
          d.BurnPermitDocumentType.BurnPermitDocumentTypeName === 'Pile Location Map'
        )
      return !hasPileLocationMap
    }

    return false
  }
)

export const documentsSectionIsValid = createSelector(
  permitNeedsForestHealthDoc,
  permitNeedsTonnageCalculationDoc,
  permitNeedsPileLocDoc,
  (needsForestDoc, needsTonnageDocs, needsPileLocDoc) => {
    const validationInfo = {
      needsForestDoc,
      needsTonnageDocs,
      needsPileLocDoc,
    }
    return {
      ...validationInfo,
      isValid: Object.values(validationInfo).every(v => v === false),
    }
  }
)

/**
 * Check to see if the permit exists, is issued, is valid, and is not suspended
 * @param {Object} state - app state
 * @param {Number} BurnPermitId - id of the burn permit to inspect
 * @returns {Array<boolean, string>} true if the permit can be used to submit requests
 */
export const permitIsActiveSelector = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return [ false, 'Invalid Permit ID provided', ]
    }

    // permit not found
    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit) {
      return [ false, 'Cannot find Permit for Permit Id ' + BurnPermitId + '.', ]
    }

    // no permit statuses means it's still an application
    if (!permit.BurnPermitStatusXrefs.exists()) {
      return [ false, 'Application is not Approved.', ]
    }

    // status isn't issued or extended
    const currentStatus = permit.BurnPermitStatusXrefs.orderBy('StatusDate', 'Desc').first()
    if (!currentStatus || (currentStatus.BurnPermitStatus && currentStatus.BurnPermitStatus.BurnPermitStatusName.includes('Issued') === false && currentStatus.BurnPermitStatus.BurnPermitStatusName !== 'Extended')) {
      return [ false, 'Permit is not Issued.', ]
    }

    const validDate = dateFormatter(permit.BurnPermitValidDate)
    const now = getNow()
    // no valid date set or permit isn't valid yet
    if (!validDate || !validDate.isValid() || now.isBefore(validDate)) {
      let msg = 'Permit is not valid'
      if (validDate) {
        msg += ' until ' + validDate.format('ddd MMM D, YYYY') + '.'
      }
      else {
        msg += ' until a DNR representative sets the Valid date.'
      }
      return [ false, msg, ]
    }
    
    const expDate = dateFormatter(permit.BurnPermitExpirationDate)
    // if an expiration date is set and we're after it
    if (expDate.isValid() && now.isAfter(expDate)) {
      return [ false, 'Permit Expired on ' + expDate.format('ddd MMM D, YYYY'), ]
    }

    const suspStart = dateFormatter(permit.SuspensionStartDate)
    const startIsValid = !!suspStart && suspStart.isValid()

    const suspEnd = dateFormatter(permit.SuspensionEndDate)
    const endIsValid = !!suspEnd && suspEnd.isValid()

    // we're in the suspension period
    if (startIsValid && now.isAfter(suspStart)) {
      // in the suspension period or no end set
      if (!endIsValid || now.isBefore(suspEnd)) {
        return [ false, 'Permit is suspended as of ' + suspStart.format('ddd MMM D, YYYY'), ]
      }
      // suspension period has ended
      return [ true, '', ]
    }
    // we're waiting for a suspension to end without a start date
    if (!startIsValid && endIsValid && now.isBefore(suspEnd)) {
      return [ false, 'Permit is suspended until ' + suspEnd.format('ddd MMM D, YYYY'), ]
    }
    return [ true, '', ]
  }
)

export const permitHasDatesSet = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return false
    }

    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit) {
      return false
    }

    const validDate = dateFormatter(permit.BurnPermitValidDate)
    const expDate = dateFormatter(permit.BurnPermitExpirationDate)
    return (validDate && validDate.isValid()) && (expDate && expDate.isValid())
  }
)

export const permitDates = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return []
    }

    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit) {
      return []
    }

    const ValidDate = dateFormatter(permit.BurnPermitValidDate, 'YYYY-MM-DD')
    const ExpirationDate = dateFormatter(permit.BurnPermitExpirationDate, 'YYYY-MM-DD')
    const SuspensionStartDate = dateFormatter(permit.SuspensionStartDate, 'YYYY-MM-DD')
    const SuspensionEndDate = dateFormatter(permit.SuspensionEndDate, 'YYYY-MM-DD')
    const RevokeStatus = permit.BurnPermitStatuses.filter({ BurnPermitStatusName: 'Revoked', }).first()
    let RevokedDate
    let RevokedComment
    if (RevokeStatus) {
      RevokedDate = dateFormatter(RevokeStatus.BurnPermitStatusXrefs.first().StatusDate, 'YYYY-MM-DD')
      RevokedComment = RevokeStatus.BurnPermitStatusXrefs.first().Comment
    }
    const SuspensionComment = permit.SuspensionComment
    return {
      ValidDate,
      ExpirationDate,
      SuspensionStartDate,
      SuspensionEndDate,
      SuspensionComment,
      RevokedDate,
      RevokedComment,
    }
  }
)

/**
 * Check to see if the permit is suspended
 * @param {Object} state - app state
 * @param {Number} BurnPermitId - id of the burn permit to inspect
 * @returns {Boolean} true if the permit can be used to submit requests
 */
export const permitIsSuspendedSelector = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => {
    if (!permit) {
      return false
    }

    const now = getNow()

    const suspStart = dateFormatter(permit.SuspensionStartDate)
    const startIsValid = !!suspStart && suspStart.isValid()

    const suspEnd = dateFormatter(permit.SuspensionEndDate)
    const endIsValid = !!suspEnd && suspEnd.isValid()

    // we're in the suspension period 
    if (startIsValid && now.isAfter(suspStart)) {
      if (!endIsValid || now.isBefore(suspEnd)) {
        return true
      }
      return false
    }
    // unknown start, check that we're after the end
    if (!startIsValid && endIsValid && now.isBefore(suspEnd)) {
      return true
    }
    return false
  }
)

/**
 * Check to see if the permit is revoked
 * @param {Object} state - app state
 * @returns {Boolean} true if the permit can be used to submit requests
 */
export const permitIsRevokedSelector = ormByIdSelector(
  permitStatusHistory,
  (_, __, history) => {
    if (!Array.isArray(history) || !history.length) {
      return false
    }
    return history[0].Status === 'Revoked'
  }
)

/**
 * Check to see if the permit is expired
 * @param {Object} state - app state
 * @returns {Boolean}
 */
export const permitIsExpiredSelector = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => {
    if (!permit) {
      return false
    }
    
    return isAfter(permit.BurnPermitExpirationDate, (new Date()).toJSON())
  }
)

/**
 * Check to see if the permit is denied
 * @param {Object} state - app state
 * @returns {Boolean}
 */
export const permitIsDeniedSelector = ormByIdSelector(
  permitStatusHistory,
  (session, history) => {
    if (!Array.isArray(history) || !history.length) {
      return false
    }
    return history[0].Status === 'Denied'
  }
)

export const permitMapDataSelector = ormWithPropSelector(
  ({ BurnPermitSearch, }, showLandownerAgent) => {
    
    const fields = [
      {
        name    : 'BurnPermitId',
        visible : false,
      },
      {
        name  : 'BurnPermitNumber',
        label : 'Burn Permit Number',
      },
      {
        name  : 'BurnTypes',
        label : 'Burn Types',
      },
      {
        name  : 'Address',
        label : 'Address',
      },
      {
        name  : 'LegalDesc',
        label : 'Legal Desc.',
      },
      {
        name  : 'HarvestAcres',
        label : 'Harvest Acres',
      },
      {
        name  : 'ExpirationDate',
        label : 'Expiration Date',
      },
      {
        name  : 'Status',
        label : 'Status',
      },
      {
        name  : 'StatusDate',
        label : 'Status Date',
      },
      {
        name  : 'TotalRequestedAcres',
        label : 'Total Requested Acres',
      },
      {
        name  : 'TotalPermitTonnage',
        label : 'Est. Permit Tonnage',
      },
      {
        name  : 'TotalRequestedTons',
        label : 'Total Requested Tons',
      },
      {
        name  : 'PlannedIgnitionDates',
        label : 'Planned Ignition Dates',
      },
      {
        name  : 'IsUGA',
        label : 'Is in UGA',
      },
      {
        name  : 'IsForestHealthExempt',
        label : 'Is Forest Health Exempt',
      },
      {
        name  : 'UnitName',
        label : 'Burn Unit Name',
      },
      {
        name  : 'LatLong',
        label : 'Lat, Long',
      },
    ]

    if (showLandownerAgent) {
      const landowner = {
        name  : 'Landowner',
        label : 'Landowner',
      }
      const agent = {
        name  : PERSON_TYPE_AGENT,
        label : PERSON_TYPE_AGENT,
      }
      fields.splice(fields.findIndex(c => c.name === 'BurnTypes'), 0, landowner)
      fields.splice(fields.findIndex(c => c.name === 'BurnTypes'), 0, agent)
    }

    const layer = {
      layerTitle : 'Burn Permits',
      layerId    : 'BurnPermits',
      renderer   : BURN_PERMIT_STATUS_POINT,
      data       : [],
      fields,
      idField    : 'BurnPermitId',
      title      : 'Burn Permit Application ID: {BurnPermitId}',
    }
    
    let data = BurnPermitSearch.all().filter(r => !!r.Longitude && !!r.Latitude)
    if (data.exists() === false) {
      return layer
    }
    data = data.toRefArray()
    
    for (let i = 0, len = data.length; i < len; i++) {
      const row = data[i]
      const {
        Latitude,
        Longitude,
        BurnPermitId,
        BurnPermitNumber,
        Address,
        BurnAcres,
        BurnTypes,
        BurnUnitName,
        BurnPermitStatus,
        BurnPermitStatusDate,
        BurnPermitApplicationStatus,
        BurnPermitApplicationStatusDate,
        BurnPermitExpirationDate,
        PlannedIgnitionDates,
        TotalPermitTonnage,
        TotalRequestedAcres,
        TotalRequestedTons,
        IsUGA,
        IsForestHealthExempt,
      } = row

      let Agent = '', Landowner = ''
      if (showLandownerAgent) {
        Agent = row.Agent
        Landowner = row.Landowner
      }
      
      const symbol = GetPermitSymbolByStatus(BurnPermitStatus || BurnPermitApplicationStatus, IsUGA)
      layer.data.push({
        geometry: {
          type      : 'point',
          latitude  : Latitude,
          longitude : Longitude,
        },
        attributes: {
          Agent,
          Landowner,
          BurnPermitNumber,
          BurnTypes,
          Address,
          PlannedIgnitionDates,
          TotalRequestedAcres,
          TotalPermitTonnage,
          TotalRequestedTons,
          BurnPermitId         : BurnPermitId.toString(),
          UnitName             : BurnUnitName,
          LegalDesc            : formatLegalDesc(row),
          Status               : BurnPermitStatus || BurnPermitApplicationStatus,
          StatusDate           : dateFormatter(BurnPermitStatusDate || BurnPermitApplicationStatusDate, 'MMM DD, YYYY'),
          ExpirationDate       : dateFormatter(BurnPermitExpirationDate, 'MMM DD, YYYY'),
          HarvestAcres         : BurnAcres,
          IsUGA                : IsUGA ? 'Yes' : 'No',
          IsForestHealthExempt : IsForestHealthExempt === null ? 'N/A' : IsForestHealthExempt ? 'Yes' : 'No',
          LatLong              : `${Latitude}, ${Longitude}`,
        },
        symbol,
      })
    }
    return layer
  }
)

export const permitSummaryDataComplete = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return false
    }
    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit) {
      return false
    }
    const allDataExists = []
    
    if (permit.BurnerId) {
      allDataExists.push(permit.Burner != null)
    }
    
    if (permit.AgentId) {
      allDataExists.push(permit.Agent != null)
      if (permit.Agent != null){
        allDataExists.push(permit.Agent.Agencies !== null && permit.Agent.Agencies.count() > 0)
      }
    }
    
    allDataExists.push(permit.BurnPermitSiteId !== null && permit.BurnPermitSite != null)
    allDataExists.push(permit.BurnPermitAreaId !== null && permit.BurnPermitArea != null)
    allDataExists.push(permit.BurnPermitLocationId !== null && permit.BurnPermitLocation != null)
    allDataExists.push(permit.BurnPermitStatuses !== null && permit.BurnPermitStatuses.count() > 0)
    allDataExists.push(permit.BurnPermitSignature !== null && permit.BurnPermitSignature.count() > 0)

    
    // If it is a Permit that is for Forest Health, check to see if we have the Exemption data
    const isForestHealthBurn = permit.BurnPermitSite && permit.BurnPermitSite.BurnReason && permit.BurnPermitSite.BurnReason.BurnReasonName === 'FOREST HEALTH'
    // All migrated permits have a Forest Health Exempt record, but they don't have the new burn reason
    // So if it's a burn request against a migrated permit, use a fallback to get the data in case it is exempt
    if (isForestHealthBurn || permit.LegacyId !== null) {
      allDataExists.push(permit.ForestHealthExempt !== null)
    }

    return allDataExists.every(d => d)
  }
)

export const burnPermitNumberById = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return ''
    }
    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit) {
      return ''
    }
    return permit.BurnPermitNumber || ''
  }
)

export const burnTypesByBurnPermitId = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return []
    }
    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit || !permit.BurnPermitArea) {
      return []
    }
    return permit.BurnPermitArea.BurnTypes.toModelArray().map(t => t.BurnTypeName)
  }
)


export const userIsBurnerOrAgent = createSelector(
  (state, permitId) => permitId,
  permitByIdSelector,
  userSelector,
  (permitId, permit, user) => {
    if (isNaN(permitId) || !permit || !user) {
      return false
    }
    if (permit.BurnerId === user.personId) {
      return true
    } else if (permit.AgentId === user.personId && user.isVerifiedAgent) {
      return true
    }
    return false
  }
)

export const permitApplicationStatus = ormByIdSelector(	
  ({ BurnPermit, }, BurnPermitId) => {	
    if (isNaN(BurnPermitId)) {	
      return null	
    }	
    const permit = BurnPermit.withId(BurnPermitId)	
    if (!permit) {	
      return null	
    }	

    const xref = permit.BurnPermitApplicationStatusXrefs.orderBy('CreateDate', 'desc').first()	
    if (xref) {	
      return {	
        BurnPermitApplicationStatusXrefId : xref.BurnPermitApplicationStatusXrefId,	
        CreateBy                          : xref.CreateBy,	
        StatusDate                        : xref.StatusDate,	
        SiteInspected                     : xref.SiteInspected,	
        Comment                           : xref.Comment,	
        Status                            : xref.BurnPermitApplicationStatus ? xref.BurnPermitApplicationStatus.BurnPermitApplicationStatusName : null,	
        StatusDescription                 : xref.BurnPermitApplicationStatus ? xref.BurnPermitApplicationStatus.BurnPermitApplicationStatusDescription : null,	
        BurnPermitApplicationStatusId     : xref.BurnPermitApplicationStatusId,	
        CreateDate                        : xref.CreateDate,	
        UpdateBy                          : xref.UpdateBy,	
        UpdateDate                        : xref.UpdateDate,	
      }	
    }	
    return null	
  }	
)

const personIsGovAgent = (person) => {
  if (!person || !person.PersonType) {
    return false
  }
  return person.PersonType.PersonTypeName === PERSON_TYPE_GOV_AGENT
}

const burnerIsGovAgentType = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => permit && personIsGovAgent(permit.Burner)
)

const agentIsGovAgentType = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => permit && personIsGovAgent(permit.Agent)
)

export const activePermitIsLegacy = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => permit ? !!permit.LegacyId : false
)

export const isGovAgentPermit = ormSelector(
  burnerIsGovAgentType,
  agentIsGovAgentType,
  (session, burnerIsGovAgent, agentIsGovAgent) => burnerIsGovAgent || agentIsGovAgent
)


export const permitIsInUGA = ormByIdSelector(
  ({ BurnPermit, }, permitId) => {
    if (isNaN(permitId)) {
      return false
    }
    const permit = BurnPermit.withId(permitId)
    if (!permit || !permit.BurnPermitLocation) {
      return false
    }
    const { IsUGA, } = permit.BurnPermitLocation
    return IsUGA
  }
)


export const permitIsForForestHealth = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {	
    if (isNaN(BurnPermitId)) {	
      return false	
    }	
    const permit = BurnPermit.withId(BurnPermitId)	
    if (!permit) {	
      return false	
    }	

    let isForestHealth = permit.ForestHealthExempt !== null && permit.ForestHealthExempt.ForestHealthExemptionApprovalFlag
    if (!isForestHealth && permit.BurnPermitSite && permit.BurnPermitSite.BurnReason) {
      isForestHealth = permit.BurnPermitSite.BurnReason.BurnReasonName === 'FOREST HEALTH'
    }
    return isForestHealth
  }	
)

export const permitHasSignedPermitDoc = ormWithPropSelector(
  permitIsExtended,
  signatureByPermitId,
  permitApplicationSignedByDnr,
  ({ BurnPermitDocument, }, BurnPermitId, isExtended, signature, signedByDnr) => {
    if (isNaN(BurnPermitId)) {
      return false
    }
    let hasSignedPermit = BurnPermitDocument
      .filter({ BurnPermitId, })
      .toModelArray()
      .some(d =>
        d.BurnPermitDocumentType
        && d.BurnPermitDocumentType.BurnPermitDocumentTypeName === 'Signed Permit'
      )
    // Also check if the permit status is extended
    // and if the applicant has signed, don't consider the
    // previous signed docs as valid as long as it is not
    // an app that was created for them by DNR
    if (isExtended && !signature.PermitApplicantSignedBy && !signedByDnr) {
      hasSignedPermit = false
    }
    return hasSignedPermit
  }
)


export const permitsByIds = ormWithPropSelector(
  ({ BurnPermit, }, BurnPermitIds) => {
    if (Array.isArray(BurnPermitIds) === false || BurnPermitIds.length === 0) {
      return []
    }
    return BurnPermit.filter(p => BurnPermitIds.includes(p.BurnPermitId)).toModelArray()
  }
)


const arrayValuesAreUnique = arr => arr.filter((val, idx, arr) => arr.indexOf(val) === idx).length === 1


export const checkAllPermitsBelongToSameBurnerOrAgent = ormWithPropSelector(
  ({ BurnPermit, }, BurnPermitIds) => {
    let allSameBurner, allSameAgent
    if (Array.isArray(BurnPermitIds) === false || BurnPermitIds.length === 0) {
      allSameBurner = false
      allSameAgent = false
      return { allSameBurner, allSameAgent, }
    }
    
    const permits = BurnPermit.filter(p => BurnPermitIds.includes(p.BurnPermitId)).toRefArray()
    
    // Checks that all the BurnerIds are the same, if there are any
    const burnerIds = permits.map(p => p.BurnerId)
    if (burnerIds.length) {
      allSameBurner = arrayValuesAreUnique(burnerIds)
    }
    // Checks that all the AgentIds are the same, if there are any
    const agentIds = permits.map(p => p.AgentId)
    if (agentIds.length) {
      allSameAgent = arrayValuesAreUnique(agentIds)
    }
    
    return { allSameBurner, allSameAgent, }
  }
)

export const hasIssuedPermits = ormWithPropSelector(
  ({ Agency, BurnPermit, }, BurnPermitIds) => {
    if (Array.isArray(BurnPermitIds) === false || BurnPermitIds.length === 0) {
      return false
    }
    return BurnPermit.filter(p => BurnPermitIds.includes(p.BurnPermitId))
      .toModelArray()
      .map(p => {
        let autoAgency = false
        if (p.Agency && p.Agency.AutoRegionApproval) {
          autoAgency = true
        } else {
          const agency = Agency.withId(p.AgencyId)
          if (agency && agency.AutoRegionApproval) {
            autoAgency = true
          }
        }
        return {
          permitNumber: p.BurnPermitNumber,
          autoAgency,
        }
      })
      .some(n => !!n.permitNumber && !n.autoAgency)
  }
)

export const invalidSteps = createSelector(
  activeBurnPermitStateSelector,
  state => state.invalidSteps
)

export const burnPermitByIdSelector = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    return BurnPermit.withId(BurnPermitId)
  }
)

export const shouldShowApplicationNextSteps = createSelector(
  permitIsPending,
  permitApplicationIsPaid,
  permitIsSubmitted,
  permitApplicationIsSigned,
  permitIsApproved,
  permitIsDenied,
  permitIsIssued,
  agencyPaysByVoucherByPermitId,
  (isPending, isPaid, isSubmitted, isSigned, isApproved, isDenied, isIssued, paysByVoucher) => {
    return {
      isPending,
      isPaid,
      isSubmitted,
      isApproved,
      isDenied,
      isSigned,
      isIssued,
      paysByVoucher,
    }
  }
)

export const shouldShowPermitNextSteps = createSelector(
  permitIsIssued,
  permitHasPermitSignatures,
  userMustSignIssuedPermit,
  permitHasSignedPermitDoc,
  (isIssued, signatures, mustSignPermit, hasSignedDoc) => {
    return isIssued 
      && (
        // We don't need to show the PermitNextSteps if it is issued and signed.
        // One way to determine if the Permit is fully Issued and Signed is to
        // check to see if the signed docs are downloaded.
        // These docs do not get downloaded by the API unless the requisite signatures have
        // been made, so once we have those, we don't need to also check the signatures.
        // This covers all user signing scenarios, including when issuing a Permit
        // to a non-user Agent of an Agency where other Agents of that Agency are
        // users and need to make Burn Requests on the permit issued to a non-user Agent
        !hasSignedDoc
          ? (
            signatures.agency === true
            && signatures.applicant === false
          )
          : false
      )
      && mustSignPermit
  }
)

export const permitSectionIsActive = createSelector(
  burnPermitStateSelector,
  propsSelector,
  (state, sectionId) => {
    const { applications, activeBurnPermitId, } = state
    return applications[activeBurnPermitId].activeStep === sectionId
  }
)

export const permitCanAcceptDocumentUploads = ormByIdSelector(
  permitIsExpiredSelector,
  permitIsRevokedSelector,
  permitIsDeniedSelector,
  (_, __, isExpired, isRevoked, isDenied) => {
    return !isExpired && !isRevoked && !isDenied
  }
)
